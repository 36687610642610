@use 'sass:math'; @import '@/scss/variables.scss';
@import 'card';
@import 'layout';
@import 'sidebar';
@import 'v-bar';
@import 'loader';
@import 'pages/dashboards';
@import 'pages/widgets';

// Icons

@import '../icons/font-awesome/css/fontawesome-all.css';
@import '../icons/themify-icons/themify-icons.css';
@import '../icons/simple-line-icons/css/simple-line-icons.css';

// Theme - Horizontal
@import 'horizontal';

// Theme - Dark

//@import "dark";

// Theme - rtl

@import 'rtl';

html,
body {
  height: 100%;
}

.v-application {
  font-size: 0.85rem !important;
  line-height: 1.5;
  display: flex;
}

.v-btn {
  font-size: 0.75rem !important;
}

.v-application .caption {
  font-size: 0.75rem !important;
  font-weight: 400;
  letter-spacing: 0.0333333333em !important;
  line-height: 1.15rem;
}

.v-list-item .v-list-item__title,
.v-list-item .v-list-item__subtitle {
  line-height: 1.2;
  font-size: 0.9rem !important;
}
.v-card__title {
  font-size: 0.9rem !important;
}

.tbody-th-bg {
  background-color: #ffffff !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td {
  font-size: 0.75rem !important;
}

header.v-sheet.theme--dark.v-toolbar.v-app-bar.v-app-bar--clipped.v-app-bar--fixed.v-app-bar--is-scrolled.primary {
  z-index: 999;
}

.theme--light.v-application {
  background: none !important;
}

.page-blank {
  background-color: white !important;
  height: 100%;
}

.v-input {
  font-size: 12px !important;
}

.v-label {
  font-size: 1.05em !important;
}

.v-label--active {
  max-width: 150% !important;
  transform: translateY(-27px) scale(0.9) !important;
  background-color: #ffffff !important;
  padding-right: 4px !important;
}

#main-sidebar {
  z-index: 999;
}

.label-opt-down {
  /* background-color: lightgrey; */
  margin-top: 0 !important;
  min-width: 35px !important;
  height: 35px !important;
  border-left: lightgrey 1px solid;
  border-right: lightgrey 1px solid;
  border-bottom: lightgrey 1px solid;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 0 !important;
  z-index: 95;
}

.filterOpt {
  z-index: 100;
}

.label-more-down {
  /* background-color: lightgrey; */
  margin-top: 0 !important;
  min-width: 25px !important;
  height: 30px !important;
  border-left: lightgrey 1px solid;
  border-right: lightgrey 1px solid;
  border-bottom: lightgrey 1px solid;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  padding: 0 0 !important;
}

.input-right-align input {
  text-align: right !important;
  font-size: 1.15em;
}

.text-field-padding-sm .v-text-field__details {
  line-height: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}

.append-btn-label {
  position: sticky;
  bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  background: white;
}

.logo-text img.mt-2 {
  margin-top: 3px !important;
}

.logo-icon img {
  margin-top: -6px !important;
}

hr.v-divider.theme--light {
  margin-top: 0px;
}

.v-card__text {
  margin-top: 0px;
  padding-top: 0px;
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 2000px !important;
  }
}

.center-msg {
  -ms-transform: translateY(55%);
  transform: translateY(55%);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  //height: 100vh;
  /* padding-top: 0px;
  padding-right: 0px;
  padding-left: 0px; */
  margin-right: auto;
  margin-left: auto;
}

.full-page-container {
  width: 100%;
  height: 100vh;
  padding: 0px;
  margin: 0px;
}

.container-vuetify {
  padding-top: 15px;
  padding-right: 15px;
  padding-left: 15px;
}

.v-navigation-drawer a,
.v-toolbar__content a {
  font-weight: normal;
  text-decoration: none;
  z-index: 100;
}

.v-navigation-drawer a:hover,
.v-toolbar__content a:hover {
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.v-list.profile-menu-dropdown.v-sheet.theme--light a {
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.v-list.profile-menu-dropdown.v-sheet.theme--light a:hover {
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.hidelogo {
  display: none;
}
.searchinput {
  position: absolute;
  width: 100%;
  margin: 0;
  left: 0;
  z-index: 10;
  padding: 0 15px;
}
.descpart {
  max-width: 220px;
}

.v-toolbar__title {
  font-size: 1.1rem;
}

//User Profile Background Image

.btn-upload-img {
  position: relative;
  top: -20px;
  max-width: 30px;
  max-height: 30px;
  z-index: 15;
}

.btn-cover-photo {
  position: relative;
  top: 200px;
  z-index: 15;
}

.txt-profile-container {
  position: relative;
  background-color: rgba(0, 0, 0);
  z-index: 9;
}

h4.title.text-profile {
  color: #ffffff !important;
}

h6.subtitle-2.text-profile {
  margin-top: -8px;
  padding-bottom: 8px;
}

.img-profile-bg-container {
  overflow: hidden;
  position: relative;
  max-height: 260px !important;
  z-index: 8;
}

.my-profile-img-bg {
  background-size: cover;
  position: relative;
  overflow: hidden;
  height: 600px;
  left: 50%;
  top: 0%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -60%);
  z-index: 10;
}

.img-profile-container {
  position: relative;
  margin-top: -220px !important;
  z-index: 11;
}

.employee-profile-pict {
  max-width: 80px;
  padding: 10px;
}

.v-tabs.tab-user-profile.v-tabs--fixed-tabs.theme--light {
  position: relative;
  z-index: 15;
}

/// CSS styling for preview conformant with A4 paper size
.a4view {
  //min-height: 842px;
  max-width: 992px !important;
}

@media print {
  div {
    break-inside: avoid;
  }
}

.tb-text {
  font-size: 16px !important;
}

.numPositive {
  color: green;
}

.numNegative {
  color: red;
}
